.color-dark {
  color: var(--ion-color-dark) !important;
}

.color-light {
  color: var(--ion-color-light) !important;
}

.color-primary {
  color: var(--ion-color-primary) !important;
}

.color-secondary {
  color: var(--ion-color-secondary) !important;
}

.color-tertiary {
  color: var(--ion-color-tertiary) !important;
}

.color-success {
  color: var(--ion-color-success) !important;
}

.color-warning {
  color: var(--ion-color-warning) !important;
}

.color-danger {
  color: var(--ion-color-danger) !important;
}
