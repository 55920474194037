.simple-choice-modal .modal-wrapper {
  height: 395px;
  width: 80%;
  max-width: 400px;
  align-items: center;
  background-color: transparent;

  .inner-wrapper {
    margin: auto;
    width: 125%;
    height: 395px;
    background-color: var(--ion-color-primary-contrast);
    border: 1px solid var(--ion-color-dark);
    border-radius: 5px;
  }
}

ion-modal.simple-choice-modal {
  --height: 395px;
  --width: 400px;
}

.confirm-modal .modal-wrapper {
  height: 150px;
  width: 80%;
  max-width: 400px;
  align-items: center;
  background-color: transparent;

  .inner-wrapper {
    position: relative;
    margin: auto;
    width: 125%;
    height: 150px;
    background-color: var(--ion-color-primary-contrast);
    border: 1px solid var(--ion-color-dark);
    border-radius: 5px;
    padding: 16px 10px 0 10px;

    ion-button {
      position: absolute;
      bottom: 10px;
      width: 40%;
    }

    .left {
      left: 10px;
    }

    .right {
      right: 10px;
    }

    .text {
      font-weight: 700;
      font-size: 20px;
    }

    .sub-text {
      font-weight: 700;
      font-size: 18px;
    }
  }
}

ion-modal.confirm-modal {
  --height: 150px;
  --width: 330px;
}

.activity-modal .modal-wrapper {
  min-height: 530px;
  max-height: 100%;
}

ion-modal.activity-modal {
  --height: 530px;
}

.input-modal .modal-wrapper {
  height: 200px;
  width: 80%;
  max-width: 400px;
  align-items: center;
  background-color: transparent;

  .inner-wrapper {
    position: relative;
    margin: auto;
    width: 125%;
    height: 200px;
    background-color: var(--ion-color-primary-contrast);
    border: 1px solid var(--ion-color-dark);
    border-radius: 5px;
    padding: 16px 10px 0 10px;

    ion-button {
      position: absolute;
      bottom: 10px;
      width: 40%;
    }

    .left {
      left: 10px;
    }

    .right {
      right: 10px;
    }

    .text {
      font-weight: 700;
      font-size: 20px;
    }

    .sub-text {
      font-weight: 700;
      font-size: 18px;
    }
  }
}

ion-modal.input-modal {
  --height: 200px;
  --width: 330px;
}

.checklist-modal .modal-wrapper {
  background-color: var(--ion-color-primary-contrast);
  overflow: auto;

  .modal-wrapper {
    height: 100%;
  }
}
